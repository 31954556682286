.App {
  text-align: center;

  /*background: red;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1.1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.zoom-out-animation {
  animation: zoomOut 20s forwards;
  width: 100%;
  height: 100%;
}

.zoom-in-animation {
  animation: zoomIn 20s forwards;
  width: 100%;
  height: 100%;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 3s ease-in-out;
  opacity: 0;
}

.image-container img.active {
  opacity: 1;
}

.cover {
  position: absolute;
  width: 100%;
  height: 90vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}